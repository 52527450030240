import { Link } from "gatsby";
import React from "react";
import { Flex } from "../../elements/Flex";
import { Heading } from "../../elements/Heading";
import { Li } from "../../elements/Li";
import { Text } from "../../elements/Text";
import { Ul } from "../../elements/Ul";

const PrivacyPolicyPage = () => {
  return (
    <Flex
      py={4}
      px={3}
      width="100%"
      maxWidth="600px"
      flexDirection="column"
      margin="0 auto"
    >
      <Heading textAlign="center" mb={3}>
        WEBSITE TERMS AND CONDITIONS
      </Heading>

      <Text mb={3}>
        <strong>What's in these terms?</strong>
      </Text>
      <Text mb={3}>
        These terms tell you the rules for using our website
        https://www.leanwithlilly.com/ - please read carefully before using our site.
      </Text>
      <Text mb={3}>
        <strong>Who we are and how to contact us</strong>
      </Text>
      <Text mb={3}>
        https://www.leanwithlilly.com/ is a site operated by Lean Fitness Limited ("We").
        We are registered in England and Wales under company number 381 2440 15.
      </Text>
      <Text mb={3}>
        and have our registered office at 17-19 Foley Street, London, United Kingdom, W1W
        6DW. Our main trading address is First Floor, 17-19 Foley Street, London, United
        Kingdom, W1W 6DW.
      </Text>
      <Text mb={3}>To contact us, please email support@leanwithlilly.zendesk.com</Text>
      <Text mb={3}>
        <strong>By using our site you accept these terms</strong>
      </Text>
      <Text mb={3}>
        By using our site, you confirm that you accept these terms of use and that you
        agree to comply with them.
      </Text>
      <Text mb={3}>If you do not agree to these terms, you must not use our site.</Text>
      <Text mb={3}>
        <strong>There are other terms that may apply to you</strong>
      </Text>
      <Text mb={3}>
        These terms refer to the following additional terms, which also apply to your use
        of our site:
      </Text>

      <Ul mb={3} ml={3}>
        <Li>
          Our <Link to="/policies/privacy-policy">Privacy Policy</Link> . See further
          under How we may use your personal information.
        </Li>
        <Li>
          Our <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link> ,
          which sets out the permitted uses and prohibited uses of our site. When using
          our site, you must comply with our Acceptable Use Policy.
        </Li>
        <Li>
          Our Cookie Policy, which sets out information about the cookies on our site.
        </Li>
        <Li>
          If you purchase goods from our site, our{" "}
          <Link to="/policies/terms-of-service">
            Consumer Goods and Digital Content Terms and Conditions
          </Link>{" "}
          will apply to the sales.
        </Li>
      </Ul>

      <Text mb={3}>
        <strong>We may make changes to these terms</strong>
      </Text>
      <Text mb={3}>
        We may amend these terms from time to time. Every time you wish to use our site,
        please check these terms to ensure you understand the terms that apply at that
        time.
      </Text>
      <Text mb={3}>
        <strong>We may make changes to our site</strong>
      </Text>
      <Text mb={3}>
        We may update and change our site from time to time to reflect changes to our
        products, our users' needs, and our business priorities.
      </Text>
      <Text mb={3}>
        <strong>We may suspend or withdraw our site</strong>
      </Text>
      <Text mb={3}>Our site is made available free of charge.</Text>
      <Text mb={3}>
        We do not guarantee that our site, or any content on it, will always be available
        or be uninterrupted. We may suspend or withdraw or restrict the availability of
        all or any part of our site for business and operational reasons. We will try to
        give you reasonable notice of any suspension or withdrawal.
      </Text>
      <Text mb={3}>
        You are also responsible for ensuring that all persons who access our site through
        your internet connection are aware of these terms of use and other applicable
        terms and conditions, and that they comply with them.
      </Text>
      <Text mb={3}>
        <strong>We may transfer this agreement to someone else</strong>
      </Text>
      <Text mb={3}>
        We may transfer our rights and obligations under these terms to another
        organisation. We will always tell you in writing if this happens and we will
        ensure that the transfer will not affect your rights under the contract.
      </Text>
      <Text mb={3}>
        <strong>How you may use material on our site</strong>
      </Text>
      <Text mb={3}>
        We are the owner or the licensee of all intellectual property rights in our site,
        and in the material published on it. All such rights are reserved.
      </Text>
      <Text mb={3}>
        You may print off one copy, and may download extracts, of any page(s) from our
        site for your personal use and you may draw the attention of others within your
        organisation to content posted on our site.
      </Text>
      <Text mb={3}>
        You must not modify the paper or digital copies of any materials you have printed
        off or downloaded in any way, and you must not use any illustrations, photographs,
        video or audio sequences or any graphics separately from any accompanying text.
      </Text>
      <Text mb={3}>
        Our status (and that of any identified contributors) as the authors of content on
        our site must always be acknowledged.
      </Text>
      <Text mb={3}>
        You must not use any part of the content on our site for commercial purposes
        without obtaining a licence to do so from us or our licensors.
      </Text>
      <Text mb={3}>
        If you print off, copy, or download any part of our site in breach of these terms
        of use, your right to use our site will cease immediately and you must, at our
        option, return or destroy any copies of the materials you have made.
      </Text>
      <Text mb={3}>
        <strong>Do not rely on information on this site</strong>
      </Text>
      <Text mb={3}>
        The content on our site is provided for general information only. It is not
        intended to amount to advice on which you should rely. You must obtain
        professional or specialist advice before taking, or refraining from, any action
        based on the content on our site.
      </Text>
      <Text mb={3}>
        Although we make reasonable efforts to update the information on our site, we make
        no representations, warranties or guarantees, whether express or implied, that the
        content on our site is accurate, complete or up to date.
      </Text>
      <Text mb={3}>
        <strong>We are not responsible for websites we link to</strong>
      </Text>
      <Text mb={3}>
        Where our site contains links to other sites and resources provided by third
        parties, these links are provided for your information only. Such links should not
        be interpreted as approval by us of those linked websites or information you may
        obtain from them.
      </Text>
      <Text mb={3}>
        We have no control over the contents of those sites or resources.
      </Text>
      <Text mb={3}>
        <strong>User-generated content is not approved by us</strong>
      </Text>
      <Text mb={3}>
        This website may include information and materials uploaded by other users of the
        site, including to bulletin boards and chat rooms. This information and these
        materials have not been verified or approved by us. The views expressed by other
        users on our site do not represent our views or values.
      </Text>
      <Text mb={3}>
        <strong>Our responsibility for loss or damage suffered by you</strong>
      </Text>
      <Text mb={3}>
        We do not exclude or limit in any way our liability to you where it would be
        unlawful to do so. This includes liability for death or personal injury caused by
        our negligence or the negligence of our employees, agents or subcontractors and
        for fraud or fraudulent misrepresentation.
      </Text>
      <Text mb={3}>
        Different limitations and exclusions of liability will apply to liability arising
        as a result of the supply of any products to you, which will be set out in our{" "}
        <Link to="/policies/terms-of-service">Consumer Goods Terms and Conditions</Link> .
      </Text>
      <Text mb={3}>
        <strong>If you are a consumer:</strong>
      </Text>
      <Text mb={3}>
        Please note that we only provide our site for domestic and private use. You agree
        not to use our site for any commercial or business purposes, and we have no
        liability to you for any loss of profit, loss of business, business interruption,
        or loss of business opportunity.
      </Text>
      <Text mb={3}>
        <strong>How we may use your personal information</strong>
      </Text>
      <Text mb={3}>
        We will only use your personal information as set out in our{" "}
        <Link to="/policies/privacy-policy">Privacy Policy</Link> .
      </Text>
      <Text mb={3}>
        <strong>
          We are not responsible for viruses and you must not introduce them
        </strong>
      </Text>
      <Text mb={3}>
        We do not guarantee that our site will be secure or free from bugs or viruses.
      </Text>
      <Text mb={3}>
        You are responsible for configuring your information technology, computer
        programmes and platform to access our site. You should use your own virus
        protection software.
      </Text>
      <Text mb={3}>
        You must not misuse our site by knowingly introducing viruses, trojans, worms,
        logic bombs or other material that is malicious or technologically harmful. You
        must not attempt to gain unauthorised access to our site, the server on which our
        site is stored or any server, computer or database connected to our site. You must
        not attack our site via a denial-of-service attack or a distributed denial-of
        service attack. By breaching this provision, you would commit a criminal offence
        under the Computer Misuse Act 1990. We will report any such breach to the relevant
        law enforcement authorities and we will co-operate with those authorities by
        disclosing your identity to them. In the event of such a breach, your right to use
        our site will cease immediately.
      </Text>
      <Text mb={3}>
        <strong>Rules about linking to our site</strong>
      </Text>
      <Text mb={3}>
        You may link to our home page, provided you do so in a way that is fair and legal
        and does not damage our reputation or take advantage of it.
      </Text>
      <Text mb={3}>
        You must not establish a link in such a way as to suggest any form of association,
        approval or endorsement on our part where none exists.
      </Text>
      <Text mb={3}>
        You must not establish a link to our site in any website that is not owned by you.
      </Text>
      <Text mb={3}>
        Our site must not be framed on any other site, nor may you create a link to any
        part of our site other than the home page.
      </Text>
      <Text mb={3}>
        We reserve the right to withdraw linking permission without notice.
      </Text>
      <Text mb={3}>
        The website in which you are linking must comply in all respects with the content
        standards set out in our{" "}
        <Link to="/policies/acceptable-use-policy">Acceptable Use Policy</Link>.
      </Text>
      <Text mb={3}>
        <strong>Which country's laws apply to any disputes?</strong>
      </Text>
      <Text mb={3}>
        These terms of use, their subject matter, and their formation, are governed by
        English law. You and we both agree that the courts of England and Wales will have
        exclusive jurisdiction.
      </Text>
      <Text mb={3}>
        <strong>Our trade mark is registered</strong>
      </Text>
      <Text mb={3}>
        LEAN WITH LILLY, TM no. UK00003273506, is a UK registered trade mark of Lean
        Fitness Limited. You are not permitted to use them without our approval.
      </Text>
    </Flex>
  );
};

export default PrivacyPolicyPage;
